@import "variables";

.nav-active {
    -webkit-transition: $global_transition;
    -moz-transition: $global_transition;
    -o-transition: $global_transition;
    transition: $global_transition;
    -webkit-transform: $global_translation;
    -moz-transform: $global_translation;
    -ms-transform: $global_translation;
    -o-transform: $global_translation;
    transform: $global_translation;
}

/* START Chris R 2021.05.03 - taken from original NB site, controls nav bar scrolling behaviour */
nav {
    background-color: #343536;
    position: fixed;
    left: -500px;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 999;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 80px 6% 30px 6%;
    scrollbar-face-color: $scroll_face;
    scrollbar-track-color: $scroll_back;
    text-align: left;
}
@media screen and (min-width: 700px) {
    nav {
        width: 500px;
        padding: 100px 40px 40px 40px;
    }
}
nav::-webkit-scrollbar {
    width: $scroll_width;
}
nav::-webkit-scrollbar-thumb {
    background: rgba(137, 148, 155, 0.3);
    width: 16px;
    border-radius: 7px;
    border: 3px solid #343536;
    cursor: pointer;
}
nav::-webkit-scrollbar-thumb:hover {
    background: rgba(137, 148, 155, 0.6);
}
nav::-webkit-scrollbar-track {
    background: #343536;
}
/* END Chris R 2021.05.03 */