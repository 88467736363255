/* Chris R 2021.05.04 - Above blocks are required for SASS - please don't remove or change them */

/* Chris R 2021.06.08 - Changed solo.css to SASS import */
/* variables import MUST come before Bootstrap! */
@import "variables";

/* main bootstrap import */
@import "../bootstrap/scss/bootstrap";

/* these imports MUST come after Bootstrap! */
@import "nav";
@import "footer";
@import "fonts";
@import "titles";
@import "cards";

/* Chris R 2021.05.03 - Changed font from 'Open Sans' as per design guidelines */
/* Chris R 2021.06.08 - Split selectors to allow for use of SASS variables */
html {
    font-size: $font-size-base;
    line-height: $line-height-base;
    font-family: $font-family-sans-serif;
}

body {
    margin: $body-margin;
}

/* Chris R 2025.03.06 - Add site-wide footer license to allow downloadable site copy */
.license {
  font-size: 85%;
}

/* Chris R 2024.10.04 - Fix issue #121, caption styling */
p:has(img) {
  text-align: center;
  font-style: italic;
}

/* Chris R 2021.05.03 - Changed font from 'Open Sans' as per design guidelines */
@media (max-width: 325px) {
  html {
    font-size: 17px;
    line-height: $line-height-base;
    font-family: $font-family-sans-serif;
  }
}

/* Chris R 2024.09.18 - Implement new banner as per design guidelines @Aditya Krishnan */

/* common */
.top_banner {
    width: 100%;
    background-color: #131313;
    background-image: url('/imagesv3/x_black_bg.png');
    background-repeat: no-repeat;
}

.banner_bar {
  width: 100%;
  min-height: 56px;
  max-height: 56px;
  background: #131313;
  color: #fff;
  padding: 0 0 0 40px;
}

.banner_bar > a {
  color: #fff;
}

.banner_main {
  display: block;
  margin: 0px auto;
  color: #fff;
}

.banner_main h1 {
  color: #fff;
}

/* mobile */
/*
iPhone 15 Pro: 393 x 690px
Google Pixel 9 Pro XL: 448 x 865px
*/
@media only screen and (max-width: 599px) {

  .top_banner {
    min-height: 300px;
    background-position-x: 4rem; 
    background-position-y: 8rem;
    background-size: 80%;
  }

  .banner_bar > img {
    height: 50px;
  }

  .banner_bar > span {
    padding: 0 10px 0 0;
  }

  .banner_main {
    width: 100%;
    min-height: 100%;
    padding: 0;
  }

  .banner_main h1 {
    margin: 0.5em 0 0.5em 0;
    font-size: 2rem;
  }

  .banner_main p {
    width: 60%;
    padding: 1em;
    font-size: 80%; 
  }

}


/* desktop */
@media only screen and (min-width: 600px) {
  .top_banner {
    min-height: 479px;
    margin: 0 0 48px 0;
    background-position: center;
  }

  .banner_bar > img {
    height: 56px;
  }

  .banner_bar > span {
    padding: 0 20px 0 0;
  }

  .banner_main {
    width: 1320px;
    min-height: 423px;
    padding: 90px 0 0 0;
  }

  .banner_main h1 {
    margin: 0 0 0.5em 0;
    text-align: left;
    width: 60%;
  }

  .banner_main p {
    width: 40%;
  }
}

/* Manage headings in _sass/_titles.scss */

p, ul, ol, dl, table, pre, blockquote {
  margin-top: 0;
  margin-bottom: 1.5rem;
}

ul, ol {
  padding-left: 1.5rem;
}

dd {
  margin-left: 1.5rem;
}

blockquote {
  margin-left: 0.75rem;
  margin-right: 0;
  padding: 0rem 0.8rem;
  border-left: .3rem solid #ccc;
  color: #555;
}

blockquote p:last-child {
  margin-bottom: 0;
}

.footnote {
  color: #444;
}

.center {
  display:inline-block;
  margin: 0 auto;
}

hr {
  border: none;
  margin: 1.5rem 0;
  border-bottom: 1px solid #ccc;
  position: relative;
  top: -1px;
}

.img, iframe {
  max-width: 100%;
}

a {
word-wrap: break-word;
}

.container img {
  margin: 2em auto 2em auto;
  display: block;
  max-width: 75%;
}

@media (max-width: 635px) {
.container img {
 max-width: 100%
 }
}

table {
  width: 100%;
  border: 1px solid #ccc;
  border-collapse: collapse;
}

td, th {
  padding: .25rem .5rem;
  border: 1px solid #ccc;
}

pre, code {
  font-family: monospace;
  background-color: #eee;
}

pre {
  padding: .5rem 1rem;
  font-size: 0.8rem;
}

code {
  padding: .1rem .25rem;
}

pre > code {
  padding: 0;
}

.sharing {
  text-align: center;
}

.date {
  text-align: right;
  font-size: 75%;
  font-style: italic;
}

@media (max-width: 635px) {
.sharing {
 font-size: 85%;
 }
}

/* This gets Google to fall into place */
.social {
 text-align: right;
}

.topsocial {
	margin-bottom: 5em;
}

/* This gets Facebook to fall into place */
.social iframe {
	vertical-align: middle;
}

/* Set an optional width for your button wrappers */
.social span {
	display: inline-block;
	width: 110px;
}

/* Adjust the widths individually if you like */
.social .google {
	width: 75px;
}

.button {
  margin-top: 2em;
  margin-left: 1em;
  display: inline-block;
  border: 2px solid #111;
  border-radius: 0.4em;
  padding: 0.1em 0.75em;
  font-size: 17px;
  color: #111;
  text-align: center;
}

.button:hover {
	color: white;
	background-color: #111;
	cursor: pointer;
	text-decoration: none;
	transition: 0.5s;
}

.Facebook {
	transform: scale(1.25);
}

.sharing-buttons {
	width: 100%;
}

.ce-banner {
    z-index: 999;
    max-height: 75px;
}

/* ChrisR - 2022.03.25 - Styles for SVG-based international flags */
.fi {
    width: 48px !important;
    height: 32px;
}

/* Chris R 2021.10.22 - Required for upcoming changes from <pre> to <code> */
code {
    overflow-wrap: break-word;
    padding: 15px;
    display: block;
    margin-bottom: 10px;
}

/* Header/Navi */

#page-header {
    width: 100%;
    height: 4rem;
    position: fixed;
    top: 0px;
    background: #fff;
    opacity: 90%
}

.burger-wrapper {
  cursor: pointer;
}

/* Chris R 2021.05.04 - Set nav width as a variable */
#meny {
    background: #333;
    width: $nav_width;
    height: 100vh;
    position: fixed;
    left: 0;
    transition-timing-function: $global_transition;
    transform: translateX(-50rem);
    top: 0;
    z-index: 998;
    transition: 0.5s;
}

#menyAvPaa {
    display: none;
}

#menyAvPaa:checked ~ #meny {
    transform: translateX(0rem);
}

#burger {
    /* Chris R 2021.05.03 - position set to fixed to stop the button from scrolling away */
    position: fixed;
    cursor: pointer;
    width: 1rem;
    height: 1rem;
    left: 1rem;
    top: 1rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    /* Chris R 2021.05.03 - z-index set to 99 so button stays on top of everything else */
    /* without this, expanding the menu just hides the button and menu can't be closed */
    /* assumes another element isn't given greater z-index priority later ... */
    z-index: 999;
}

#burger > div {
    height: 2px;
    background-color: #000;
    transition: 0.5s;
    z-index: 999;
    /* Chris R 2024.09.19 - Change background color to suit new top banner */
    background-color: #fff;
}

#menyAvPaa:checked ~ #burger > div {
  background-color: #89949b;
}

#menyAvPaa:checked ~ #burger > div:nth-child(1) {
    /* transform: translateY(7.5px) rotate(45deg); // changed from 15px to 7.5px */
    transform: translateY(7px) translateX(0px) rotate(45deg) // Chris R changed for hamburger resizing
}
#menyAvPaa:checked ~ #burger > div:nth-child(2) {
    opacity: 0;
}
#menyAvPaa:checked ~ #burger > div:nth-child(3) {
    /* transform: translateY(-7.5px) rotate(-45deg); // changed from 15px to 7.5px */
    transform: translateY(-7px) rotate(-45deg); // Chris R changed from 7.5 to 7px
}

#nav-container {
    position: relative;
    top: -1.5rem;
    overflow: auto;
    /* Chris R 2021.05.18 - Fix localised version icon placement */
    padding-bottom: 4rem;
}

#nav-title {
    width: 100%;
    font-size: 0.875em;
    letter-spacing: 0.1875em;
    font-weight: 500;
    position: fixed;
    top: 1rem;
    z-index: 1000;
    text-transform: uppercase;
    cursor: default;
    color: #89949b;
}

#nav-title h1 {
    font-size: 1.875em;
    letter-spacing: 0.1875em;
    font-weight: 500;
    text-transform: uppercase;
    color: rgba(137, 148, 155, 0.5);
    margin-bottom: 0em;
    margin-left: 3rem;
    text-align: left;
}

#nav-title a:hover {
    color: #fff;
    text-decoration: none;
}

#nav-search {
    padding-top: 0rem;
    margin-left: 2rem;
    margin-right: 2rem;
}

#nav-search input[type=text], #main-search input[type=text] {
    width: 96%;
    height: 3rem;
    font-size: 1.2em;   
    background-color: #333;
    border: 1px solid #424950;
    color: rgba(137, 148, 155, 0.5);
    text-transform: uppercase;
    border-radius: 10px;
    text-indent: 10px;

}

#nav-search input[type=text]:focus {
    outline: none;
    color: #fff;
    border: 1px solid #fff;
}

#nav-search input[type=text]:hover {
    color: #fff;
}

#main-search input[type=text] {
    background-color: white;
}

#main-search input[type=text]:focus {
 outline: none;
 color: #89949b;
}

#nav-items {
    position: relative;
    margin-left: 1rem;
    margin-right: 1rem;
    /* Chris R 2021.05.03 - without this padding, the lowest content in the navbar isn't visible */
    padding-bottom: 80px;
}

#nav-items ul {
    list-style-type: none;
}

#nav-items li {
    margin-bottom: 0.8rem;
}

/* LJ - #nav-items li li wasn't working for me */
#nav-items .grandchild {
    font-size: 1em;
    margin-left: 1.5rem;
    list-style-type: circle;
    color: #89949b;
}

#nav-items a {
    color: #89949b;
}

#nav-items a:hover {
    color: #fff;
    text-decoration: none;
}

.nav-parent {
    width: 90%;
    color: #89949b;
    border-bottom: 1px solid #424950;
    font-size: 1.375em;
    font-weight: 500;
    margin-bottom: 2rem;
    margin-top: 2rem;
    margin-left: 1rem;
}

#localized-versions ul {
    list-style: none;
}

#localized-versions li {
    display: inline;
}

#localized-versions img {
    height: 2rem;
    margin-left: 1rem;
}

#localized-versions a:hover {
    text-decoration: none;
}

/* End Header/Navi */

.biblesvg {
    width: 90px;
    margin-top: 5em;
    margin-bottom: 5em;
}

.ornament {
    width: 80px;
    margin-bottom: 5em;
}

/* Localization Flags */
.localization {
    font-size: 0;
}

.localization a {
    display: inline-block;
    text-decoration: none;
    width: 36px;
    margin: 0 10px;
}

.definition {
    text-align: center;
    border: 1px solid #eaeaea;
    padding: 40px 0;
    margin-bottom: 3em;
}

/* Figures/Images */
figure {
    -webkit-margin-before: 0em;
    -webkit-margin-after: 0em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
}

figure figcaption {
    font-size: 0.857142em;
    margin-bottom: 2.28em;
    counter-increment: figures;
}

figure figcaption:before {
    content: 'Figure: ';
}

figure img {
    margin-top: 1em;
    max-height: 500px;
}

/* Notes */
.note-h6 {
    display: none;
}
.note-h5 {
    margin-left: 0em;
}
.note-content {
    font-size: 0.75em;
    border: 1px solid #eaeaea;
    padding: 5px 20px;
    margin-bottom: 2.6em;
}

.note {
    font-size: 0.75em;
    border: 1px solid #eaeaea;
    padding: 5px 20px;
    margin-bottom: 2.6em;
  }
  .note > h5 {
    margin-left: 0em;
  }
  .note h6 {
    display: none;
  }
  .note h1 {
    font-size: 1em;
    text-align: left;
  }

.noteEmbedded {
    font-size: 0.75em;
    border: 1px solid #eaeaea;
    padding: 5px 20px;
    margin-bottom: 2.6em;
    margin-left: 2.2em;
    float: right;
}
.noteEmbedded h6 {
    display: none;
}
.noteEmbedded h5 {
    padding: 4px 20px;
    font-size: 15px;
}

@media screen and (min-width: 700px) {
    .note {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    .note p {
      width: 540px;
    }
    .note ul,
    .note ol {
      width: auto;
    }
    .noteEmbedded {
        width: 280px;
        margin-left: 10px;
        margin-right: auto;
      }
      .noteEmbedded p {
        width: 240px;
      }
      .noteEmbedded ul,
      .noteEmbedded ol {
        width: auto;
      }
}

/* 2021.06.10 ChrisR - should fix nav width on mobile devices */
@media screen and (max-device-width: 700px) {
    #meny {
        width: 100%;
        height: 100%;
    }
    #nav-container {
        top: 2rem;
    }
    #nav-title {
        width: 75%;
        margin-left: 30px;
    }
    #nav-title h1 {
        text-align: center;
    }
    #nav-container {
        top: 2rem;
    }
    // we can fix the width once we fix issue #34
    // this temporarily fixes the overlapping text on hamburger
    /*
    .container {
        // margin-left: 3rem;
        // margin-right: 2rem;
        max-width: 70vw;
    }
    */
}

/* Custom Page Navi Buttons */
.page-navi {
    display: block;
    width: auto;
    overflow: hidden;
}

.page-navi a {
    display: block;
    width: 50%;
    float: left;
    margin: 1em 0;
}

.page-navi-next {
    text-align: right;
}

.page-navi-prev:before {
    content: '<-- ';
}

.page-navi-next:after {
    content: ' -->';
}

/* Search Results */
#results-container {
    background-color: #eaeaea;
    border-radius: 5px;
    color: #89949B;
    margin-left: 2rem;
    margin-right: 2rem;
}

#results-container a {
    color: #89949B;
}

#results-container a:hover {
    color: #fff;
    text-decoration: none;
}

#results-container li {
    list-style: none;
    margin-left: 1rem;
    margin-bottom: 2px;
}

// Page Navigation Links
// WK 2021-05-20
.previous-next-page-links {
    display: flex;
    flex-direction: row;
    padding: 2rem 0;

    font-size: 1.2rem;

    div {
        width: 100%;
    }

    .arrow {
        &::before, &::after {
            display:inline-block;
            text-decoration: none;
        }
    }

    .arrow__left {
        &::before {
            content: "\2190";
            padding-right: .3em;
        }
    }

    .arrow__right {
        &::after {
            content: "\2192";
            padding-left: .3em;
        }
    }

    .right {
        text-align: right;
    }
}

// Book Chapters
// WK 2021-05-20
.book-chapters {
    h2 {
        margin-top: 2rem;
        font-size: 2rem;
        font-weight: 800;
        margin-bottom: 2rem;
        margin-right: -4rem;
    }
}

i.fa-link {
    display: none;
}
:is(h1, h2, h3, h4, h5, h6):hover i.fa-link {
    display: inline-block;
    color: rgba(137, 148, 155, 0.5);
}

// release note list style to prevent extra line break
ul.release-notes {
    margin-bottom: 0px
}
