/* css for title elements only */

/* Chris R 2024.04.18 - New home page TOC titles as per Chris McCann layout */
.home_level1 h2 {
    display: inline-block;
    margin: 2px 0;
    font-size: 1.5rem;
    font-weight: normal;
}

ul.home_level1,
ul.home_level2,
ul.home_level3 {
    list-style-type: none;
}

.section_heading {
  border-bottom: 1px solid #444;
  font-size: 1.8rem;
  font-weight: bold;
  display: block;
  margin: 0 0 15px 0;
}

.section_subheading {
  font-size: 1.4rem;
  font-weight: bold;
  display: block;
  margin: 0 0 10px 0;
}

.section_desc {
  font-weight: normal;
  font-size: 0.9rem;
  display: block;
}

/* end 2024 TOC */

/* Chris R 2021.05.03 - Changed font from 'Open Sans' as per design guidelines */
 h1, h2, h3, h4, h5, h6 {
    margin: 0 0 0.5rem -0.1rem;
    color: $body-color;
    text-rendering: optimizeLegibility;
    font-family: $font-family-sans-serif;
    line-height: 1.2
 }
 
@media (max-width: 750px) {
h1, h2, h3, h4, h5, h6 {
    margin-left: 0 !important;
    margin-right: 0 !important;
    }
 }

 h1 {
     font-size: 2.5rem;
     margin-bottom: 5rem;
     font-weight: 700;
     margin-left: -2rem;
     margin-right: -2rem;
     text-align: center;
 }

h1 a {
    color: inherit;
}

@media (min-width: 30rem) {
h1 {
    font-size: 4rem;
    margin-bottom: 7rem;
    }
}

h2 {
    margin-top: 2rem;
    font-size: 2rem;
    font-weight: 800;
    margin-bottom: 3rem;
    margin-right: -4rem;
}

@media (min-width: 30rem) {
h2 {
    margin-top: 5rem;
    font-size: 2rem;
    margin-bottom: 5rem;
    margin-right: -4rem;
    }
}

h3 {
    margin: 2rem 0 1.75rem 0;
    margin-top: 2rem;
    font-size: 1.5rem;
}

@media (min-width: 30rem) {
h3 {
    margin-top: 2.5rem;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    }
}

h4, h5, h6 {
    margin: 1.5rem 0 1.5rem 0;
    font-size: 1.5rem;
}

h1.single-page-title {
    text-align: center;
    margin: 0 0 1rem 0;
}

h2.single-page-subtitle {
    text-align: center;
    margin: 1rem 0 3rem 0;
}

h5.single-page-versions {
    text-align: center;
    margin: -1.5rem 0 1rem 0;
    font-size: 0.8rem
}
