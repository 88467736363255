/* Chris R 2021.05.03 - Global fonts as per Nutanix branding guidelines at https://www.nutanix.com/branding */
/* Chris R 2022.08.30 - Old fonts removed, new fonts added.  Creative Team directive. */

/* Chris R 2023.04.04 - Rebrand font.  Creative Team directive. */
@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-Regular.woff2') format('woff2');
    font-weight: 300;
}

