/* styles for homepage cards */

.card-container {
    text-align: center;
    width: 100%;
    margin: 2rem 0;
}

.card-home {
    width: $card-width;
    height: $card-height;
    display: inline-block;
    overflow: hidden;
}

.card-image {
    width: $card-image-width;
    padding: 0;
}

.card-body-home {
    padding: 0 1rem;
}

.card-title-home {
    font-size: $card-title-font-size;
    font-weight: $card-title-font-weight;
}

.card-title-home a,
.card-text a {
    color: $body-color !important;
}
