/* fonts and styling */
/* progressively moving stuff into variables */

/* ONLY MODIFY THIS SECTION! */
/*
    These variables are all provided by Bootstrap v5.
    For a full list see /bootstrap/scss/_variables.scss
    but DO NOT modify that file!
*/

/* Chris 2023.04.04 - Nutanix Rebrand */
$body-color: #131313;
$font-family-sans-serif: "Montserrat", "Arial", sans-serif;
$link-color: #4100A0;
$line-height-base: 1.6;
$font-size-base: 1rem;
$link-decoration: none;
$border-radius: 0;

$footer-font-color: #131313;
$footer-font-size: 12px;
$body-margin: 0px;

$card-width: 18rem;
$card-height: 17rem;
$card-image-width: 15%;
$card-title-font-size: 1rem;
$card-title-font-weight: 800;
/* STOP EDITING! */

$html_font_size: $font-size-base;
$html_line_height: $line-height-base;
$html_font_family: $font-family-sans-serif;

$body_font_color: $body-color;
$body_link_color: $link-color;

/* nav menu styles */
$scroll_face: #424950;
$scroll_back: #343536;
$scroll_width: 14px;
$nav_width: 30rem;

/* element transition/translations used throughout the site */
$global_transition: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) all;
$global_translation: translate3d(32rem, 0, 0);
